<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <ValidationProvider name="file" rules="" v-slot="{valid, errors}">
                <div>
                    <div class="d-flex mb-1">
                        <div class="label-as-input flex-grow-1 mr-2">
                            {{ file ? file.file_name : '' }}
                        </div>
                    </div>
                    <div class="border rounded p-4 mb-4">
                        <div class="d-flex flex-column flex-md-row">
                            <div class="flex-grow-1 mr-2">
                                <b-form-group :label="$t('upload_file')">
                                    <div class="d-flex custom-file-upload">
                                        <b-form-file v-model="formData.file"
                                                     :placeholder="$t('select_file')"
                                                     :drop-placeholder="$t('drop_file')"
                                                     ref="fileInput"
                                                     accept="application/pdf,.docx"
                                        />
                                        <b-button variant="outline-secondary"
                                                  @click="()=>{$refs.fileInput.$el.childNodes[0].click();}">
                                            {{ $t('browse') }}
                                        </b-button>
<!--                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>-->
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                </div>
            </ValidationProvider>
            <ValidationProvider name="note" rules="required" v-slot="{valid, errors}">
                <b-form-group :label="$t('note_area')">
                    <b-form-textarea v-model="formData.note"
                                     :class="errors[0] ? 'is-invalid':''"
                                     rows="5"
                    />
                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                </b-form-group>
            </ValidationProvider>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary"
                          @click="updateForm"
                          :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Services
import StudentNoteService from '@/services/StudentNoteService';

// Other
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    components: {
        ValidationProvider,
        ValidationObserver
    },
    props: {
        studentProgram: {
            type: Object,
            default: null
        },
        formId: {
            type: Number
        }
    },
    data() {
        return {
            formData: {
                note: null,
                file: null
            },
            file:null,
            formLoading: false
        }
    },
    watch: {
        formId: function (val) {
            this.get(val)
        }
    },
    async created() {
        await this.get(this.formId)
    },
    methods: {
        get(id) {
            StudentNoteService.get(id)
                .then(response => {
                    let data = response.data.data;
                    this.formData = {
                        note: data.note,
                    }
                    this.file=data.file;
                })
        },
        async updateForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid && this.studentProgram.student_number) {

                let formData = new FormData();

                formData.append('student_number', this.studentProgram.student_number)
                formData.append('note', this.formData.note)
                formData.append('file', this.formData.file)

                this.formLoading = true;
                StudentNoteService.update(this.formId, formData)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.$emit('updateFormSuccess', true);
                    })
                    .catch((e) => {
                        this.showErrors(e, this.$refs.formModalValidate)
                    })
                    .finally(() => {
                        this.formLoading = false
                    })
            }
        }
    }
}
</script>

