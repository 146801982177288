<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col md="6">
                    <b-card :header="$t('general')" class="soft">
                        <ValidationProvider name="semester_id" rules="required" v-slot="{ errors }">
                            <b-form-group :label="$t('academic_year') + ' / ' + $t('period')">
                                <semesters-selectbox v-model="formData.semester_id" :without-summer="true"
                                    :without-years="true" :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                        <!-- <ValidationProvider name="ombudsman_id" rules="required" v-slot="{ errors }">
                            <b-form-group :label="$t('ombudsman')">
                                <staff-auto-complete v-model="formData.ombudsman_id" :returnValue="'id'"
                                    :validateError="errors[0]" :valueObject="ombudsmanObject" />
                            </b-form-group>
                        </ValidationProvider> -->
                        <ValidationProvider name="explanation" rules="required" v-slot="{ errors }">
                            <b-form-group :label="$t('explanation')">
                                <b-textarea v-model="formData.explanation" rows="3" max-rows="3"
                                    :state="errors[0] ? false : null" />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                    </b-card>
                </b-col>
                <b-col md="6">
                    <b-card :header="$t('decision')" class="soft">
                        <ValidationProvider name="decision" rules="required" v-slot="{ errors }">
                            <b-form-group :label="$t('decision')">
                                <parameter-selectbox v-model="formData.decision" code='discipline_decisions'
                                    :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="decision_number" rules="required" v-slot="{ errors }">
                            <b-form-group :label="$t('decision_number')">
                                <b-form-input v-model="formData.decision_number" :state="errors[0] ? false : null"
                                    type="number" />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="decision_date" rules="required" v-slot="{ errors }">
                            <b-form-group :label="$t('decision_date')">
                                <select-date v-model="formData.decision_date" :validationError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                    </b-card>
                </b-col>
                <b-col md="12" v-if="formData.decision == 'approved'">
                    <b-card :header="$t('punishment')" class="soft">
                        <b-row>
                            <b-col md="4">
                                <ValidationProvider name="punishment" rules="required" v-slot="{ errors }">
                                    <b-form-group :label="$t('punishment')">
                                        <parameter-selectbox v-model="formData.punishment" code='discipline_punishments'
                                            :validateError="errors[0]" />
                                    </b-form-group>
                                </ValidationProvider>
                                <ValidationProvider name="punishment_number" rules="required" v-slot="{ errors }">
                                    <b-form-group :label="$t('punishment_number')">
                                        <b-form-input v-model="formData.punishment_number" :state="errors[0] ? false : null"
                                            type="number" />
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="4">
                                <ValidationProvider name="deleted_semesters" :rules="formData.punishment == 4 ? 'required' : ''"
                                    v-slot="{ errors }" v-if="formData.punishment == 4">
                                    <b-form-group :label="$t('frozen_semesters')">
                                        <multi-selectbox v-model="formData.deleted_semesters" :options="semesters"
                                            :multiple="true" :validateError="errors[0]" />
                                    </b-form-group>
                                </ValidationProvider>
                                <b-form-group :label="$t('frozen_semesters')" v-else>
                                    <div class="label-as-input">-</div>
                                </b-form-group>

                                <ValidationProvider name="punishment_start_date" rules="required" v-slot="{ errors }"
                                    v-if="[3, 4].includes(parseInt(formData.punishment))">
                                    <b-form-group :label="$t('punishment_start_date')">
                                        <select-date v-model="formData.punishment_start_date"
                                            :validationError="errors[0]" />
                                    </b-form-group>
                                </ValidationProvider>
                                <b-form-group :label="$t('punishment_start_date')" v-else>
                                    <div class="label-as-input">-</div>
                                </b-form-group>

                                <ValidationProvider name="punishment_end_date" rules="required" v-slot="{ errors }"
                                    v-if="[3, 4].includes(parseInt(formData.punishment))">
                                    <b-form-group :label="$t('punishment_end_date')">
                                        <select-date v-model="formData.punishment_end_date" :validationError="errors[0]" />
                                    </b-form-group>
                                </ValidationProvider>
                                <b-form-group :label="$t('punishment_end_date')" v-else>
                                    <div class="label-as-input">-</div>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <ValidationProvider name="stay_of_execution_decision" rules="required" v-slot="{ errors }">
                                    <b-form-group :label="$t('stay_of_execution_decision')">
                                        <b-form-select v-model="formData.stay_of_execution_decision"
                                            :options="stayOfExecutionDecisionOptions" :state="errors[0] ? false : null" />
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                    </b-form-group>
                                </ValidationProvider>
                                <!-- <ValidationProvider name="explanation" rules="required" v-slot="{errors}">
                                    <b-form-group :label="$t('explanation')">
                                        <b-textarea v-model="formData.explanation"
                                                    rows="3"
                                                    max-rows="3"
                                                    :state="errors[0] ? false : null"
                                        />
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider> -->
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </ValidationObserver>
        <div class="d-flex justify-content-center mt-2">
            <b-button variant="primary" @click="updateForm" :disabled="formLoading">
                <b-spinner label="Spinning" class="mr-3" small v-show="formLoading"></b-spinner>
                {{ $t('save') }}
            </b-button>
        </div>
    </div>
</template>

<script>
import MultiSelectbox from '@/components/interactive-fields/MultiSelectbox';
import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
import SelectDate from '@/components/interactive-fields/SelectDate';
import SemestersSelectbox from '@/components/interactive-fields/SemestersSelectbox';
import StaffAutoComplete from '@/components/interactive-fields/StaffAutoComplete';

// Services
import DisciplinesService from '@/services/DisciplinesService';

// Other
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import SemesterService from '@/services/SemesterService'
import moment from 'moment/moment'

export default {
    components: {
        MultiSelectbox,
        ParameterSelectbox,
        SelectDate,
        SemestersSelectbox,
        StaffAutoComplete,

        ValidationObserver,
        ValidationProvider
    },
    props: {
        formId: {
            type: Number
        }
    },
    data() {
        return {
            formLoading: false,
            formData: {
                student_program_id: null,

                semester_id: null,
                // ombudsman_id: null,

                decision: null,
                decision_number: null,
                decision_date: null,

                punishment: null,
                punishment_number: null,
                punishment_start_date: null,
                punishment_end_date: null,
                deleted_semesters: [],
                stay_of_execution_decision: 1,
                explanation: null
            },
            semesters: [],
            // ombudsmanObject: {},
            stayOfExecutionDecisionOptions: [
                { value: 1, text: this.$t('yes') },
                { value: 0, text: this.$t('no') }
            ]
        }
    },
    async created() {
        await this.getSemester();
        await this.get(this.formId)
    },
    methods: {
        getSemester() {
            SemesterService.semesters()
                .then(response => {
                    let data = response.data.data.options;
                    if (data && data.length > 0) {
                        this.semesters = data.filter(item => ['1', '2'].includes(item.semester))
                            .map((item) => {
                                return {
                                    value: item.id,
                                    text: item.academic_year + ' ' + item.semester_text
                                }
                            })
                    }
                })
                .catch(e => {
                    this.showErrors(e, this.$refs.formModalValidate);
                })
        },
        get(id) {
            DisciplinesService.get(id)
                .then((response) => {
                    let data = response.data.data
                    this.formData = {
                        student_program_id: data.student_program_id,

                        semester_id: data.semester_id,
                        // ombudsman_id: data.ombudsman_id,

                        decision: data.decision,
                        decision_number: data.decision_number,
                        decision_date: data.decision_date,

                        punishment: data.punishment,
                        punishment_number: data.punishment_number,
                        punishment_start_date: data.punishment_start_date,
                        punishment_end_date: data.punishment_end_date,
                        deleted_semesters: data.deleted_semesters.map(item => parseInt(item, 10)),
                        stay_of_execution_decision: data.stay_of_execution_decision ? 1 : 0,
                        explanation: data.explanation
                    }
                    // this.ombudsmanObject = {
                    //     value: data.ombudsman_id,
                    //     text: data.ombudsman.person.name + ' ' + data.ombudsman.person.surname
                    // }
                })
                .catch(e => {
                    this.showErrors(e)
                })
        },
        async updateForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid && this.formId) {

                let formData = {
                    ...this.formData
                }
                if (formData.punishment != 4) {
                    formData.deleted_semesters = []
                }
                if ([1, 2].includes(parseInt(formData.punishment))) {
                    formData.punishment_start_date = moment().format('MM/DD/YYYY')
                    formData.punishment_end_date = moment().format('MM/DD/YYYY')
                }
                else if (parseInt(formData.punishment) == 5) {
                    formData.punishment_start_date = moment().format('MM/DD/YYYY')
                    formData.punishment_end_date = '2039-12-31'
                }
                if (formData.decision != 'approved') {
                    formData.punishment = null
                    formData.punishment_number = null
                    formData.punishment_start_date = null
                    formData.punishment_end_date = null
                    // formData.explanation = null
                }
                this.formLoading = true;
                DisciplinesService.update(this.formId, formData)
                    .then(response => {
                        this.$emit('updateFormSuccess');
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    .finally(() => {
                        this.formLoading = false
                    })
            }
        }
    }
}
</script>
