<template>
    <div>
        <h5 class="mt-1 mb-4">{{ $t('undergraduate_vertical_transfer').toUpper() }}</h5>
        <b-row>
            <b-col cols="12" md="6">
                <b-card class="soft">
                    <template #header>
                        <span v-html="$t('horizontal_or_vertical_coming_from_university_1')"></span>
                    </template>
                    <div class="label-as-input-div">
                        <label>{{ $t('university') }}</label>
                        <div>{{ studentProgram.from_university_name }}</div>
                    </div>
                    <div class="label-as-input-div">
                        <label>{{ $t('school') }}</label>
                        <div>{{ studentProgram.from_faculty }}</div>
                    </div>
                    <div class="label-as-input-div">
                        <label>{{ $t('program') }}</label>
                        <div>{{ studentProgram.from_program }}</div>
                    </div>
                    <b-row>
                        <b-col cols="12" md="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('number_of_semesters_studied') }}</label>
                                <div>{{ studentProgram.from_number_of_semesters ? this.$t('period_x', {period: studentProgram.from_number_of_semesters}) : '-' }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" md="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('gno') }}</label>
                                <div>{{ getObjectValue(studentProgram, 'from_cgpa') }}</div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" md="6">
                <b-card class="soft">
                    <template #header>
                        <span v-html="$t('horizontal_or_vertical_attended_university_1')"></span>
                    </template>
                    <div class="label-as-input-div">
                        <label>{{ $t('university') }}</label>
                        <div>{{ studentProgram.to_university_name }}</div>
                    </div>
                    <div class="label-as-input-div">
                        <label>{{ $t('school') }}</label>
                        <div>{{ studentProgram.to_faculty }}</div>
                    </div>
                    <div class="label-as-input-div">
                        <label>{{ $t('program') }}</label>
                        <div>{{ studentProgram.to_program }}</div>
                    </div>
                </b-card>
            </b-col>
        </b-row>
        <b-button variant="primary"
                  @click="updateFormShow"
                  v-if="checkPermission('studentprogram_updateeducation') && editMode">
            {{ $t('edit') }}
        </b-button>
        <CommonModal ref="formModal"
                     size="xl"
                     :onHideOnlyX="true"
                     @bHideModalHeaderClose="clearFormData()"
                     v-if="checkPermission('studentprogram_updateeducation') && editMode">
            <template v-slot:CommonModalTitle>
                {{ $t('edit').toUpper() }}
            </template>
            <template v-slot:CommonModalContent>
                <update-form :studentProgram="studentProgram"
                             @updateFormSuccess="updateFormSuccess()"
                             v-if="formProcess=='update'"
                />
            </template>
        </CommonModal>
    </div>
</template>
<script>
    // Modal
    import CommonModal from '@/components/elements/CommonModal';

    // Page
    import UpdateForm from './UpdateForm';

    export default {
        components: {
            CommonModal,
            UpdateForm
        },
        props: {
            studentProgram: {
                type: Object,
                default: null
            },
            editMode: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                formProcess: null
            }
        },
        methods: {
            // Clear
            clearFormData() {
                this.formProcess = null
            },

            // Update
            updateFormShow() {
                this.formProcess = 'update'
                this.$refs.formModal.$refs.commonModal.show()
            },
            updateFormSuccess() {
                this.clearFormData();
                this.$refs.formModal.$refs.commonModal.hide()
                this.$emit('updatedData', true);
            }
        }
    }
</script>

