<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12" lg="12">
                    <b-form-group :label="$t('asal_delay_reasons')">
                        <ValidationProvider name="request_reason" rules="required" v-slot="{valid, errors}">
                            <parameter-id-selectbox  v-model="formData.request_reason" code="asal_delay_reasons" class="mb-1" >
                            </parameter-id-selectbox>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="12">
                    <b-form-group :label="$t('asal_type')">
                        <ValidationProvider name="request_type" rules="required" v-slot="{valid, errors}">
                            <multiselect v-model="request_type" :options="asal_type" :custom-label="nameWithLang" placeholder="" label="" track-by="" selectLabel="" deselectLabel=""></multiselect>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="12">
                    <b-form-group :label="$t('asal_maximum_time_reasons')">
                        <ValidationProvider name="reason_to_increase_max_duration" rules="required" v-slot="{valid, errors}">
                            <parameter-selectbox  v-model="formData.reason_to_increase_max_duration" code="asal_maximum_time_reasons" class="mb-1"  >
                            </parameter-selectbox>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="12">
                    <b-form-group :label="$t('signature_name')">
                        <ValidationProvider name="signer_code" rules="required" v-slot="{valid, errors}">
                            <parameter-selectbox
                                v-model="formData.signer_code"
                                code="military_document_signatories"
                                class="mb-1" 
                                :validate-error="errors[0]"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
            </b-row>


            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary"
                          @click="createForm"
                          :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Components
import ParameterIdSelectbox from "@/components/interactive-fields/ParameterIdSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
// Services
import AsalService from "@/services/AsalService";
// Other
import {ValidationProvider, ValidationObserver} from "vee-validate"


export default {
    props:{
        student_program_id:null,

    },
    components: {
        ParameterSelectbox,
        ValidationProvider,
        ValidationObserver,
        ParameterIdSelectbox
    },
    data() {
        return {
            request_type:null,
            signer_national_id:null,
            asal_type:[
                { name: 'Erteleme', value: 'E' },
                { name: 'İptal', value: 'I' }

            ],
            formData: {},
            formLoading: false,
        }
    },
    methods: {
        nameWithLang ({ name}) {
            return `${name}`
        },
        async createForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            this.formData.request_type=this.request_type.value
            this.formData.student_program_id= this.student_program_id
            if (isValid) {
                this.formLoading = true;
              AsalService.store(this.formData)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.$emit('createFormSuccess', true);
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    .finally(() => {
                        this.formLoading = false
                    })
            }
        }
    }
}
</script>

