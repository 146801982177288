<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row class="mb-4">
                <b-col md="12" class="flex-column d-flex">
                    <label class="col-form-label">{{ $t('communication_address') }}</label>
                    <div class="border rounded equal-height p-4 mb-4">
                        <ValidationProvider name="contact_address" rules="required" v-slot="{ valid, errors }">
                            <b-form-group :label="$t('street')">
                                <b-form-textarea v-model="formData.contact_address"
                                                 :class="errors[0] ? 'is-invalid' : ''"
                                                 rows="3"/>
                            </b-form-group>
                        </ValidationProvider>
                        <b-row>
                            <!--                            <b-col cols="6">-->
                            <!--                                <ValidationProvider name="adress_country_id" rules="required" v-slot="{ valid, errors }">-->
                            <!--                                    <b-form-group>-->
                            <!--                                        <label>{{ $t('country') }}</label>-->
                            <!--                                        <country-selectbox :validateError="errors[0]"-->
                            <!--                                            v-model="formData.adress_country_id"></country-selectbox>-->
                            <!--                                    </b-form-group>-->
                            <!--                                </ValidationProvider>-->
                            <!--                            </b-col>-->
                            <b-col cols="6">
                                <ValidationProvider name="contact_city_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label>{{ $t('state') }}</label>
                                        <city-selectbox v-model="formData.contact_city_id"
                                                        :validateError="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="6">
                                <ValidationProvider name="contact_district_id" rules="required"
                                                    v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label>{{ $t('city') }}</label>
                                        <district-outline-selectbox v-model="formData.contact_district_id"
                                                                    :city_id="formData.contact_city_id"
                                                                    :validateError="errors[0]"
                                        />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="6">
                                <ValidationProvider name="apartment" rules="" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label>{{ $t('apartment') }}</label>
                                        <b-form-input type="text" v-model="formData.apartment"
                                                      :state="errors[0] ? false : (valid ? true : null)">
                                        </b-form-input>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="6">
                                <ValidationProvider name="address_zip_code" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label>{{ $t('zip_code') }}</label>
                                        <b-form-input type="text" v-model="formData.address_zip_code"
                                                      :state="errors[0] ? false : (valid ? true : null)">
                                        </b-form-input>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center">
                <b-button variant="primary" @click="updateForm" :disabled="formProcess">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Components
import CitySelectbox from '@/components/interactive-fields/CitySelectbox';
import DistrictOutlineSelectbox from '@/components/interactive-fields/DistrictOutlineSelectbox';

// Services
import StudentProgramService from '@/services/StudentProgramService'

// Other
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    components: {
        CitySelectbox,
        DistrictOutlineSelectbox,

        ValidationProvider,
        ValidationObserver
    },
    props: {
        studentProgram: {
            type: Object,
            default: null
        }
    },
    created() {
        this.formId = this.studentProgram.id;
        this.formData = {
            contact_address: this.studentProgram.contact_address,
            contact_city_id: this.studentProgram.contact_city_id,
            address_zip_code: this.studentProgram.address_zip_code,
            apartment: this.studentProgram.apartment,
            contact_district_id:this.studentProgram.contact_district_id

        }
    },
    mounted() {
        this.$refs.formModalValidate.reset();
    },
    data() {
        return {
            formId: null,
            formData: {},
            formProcess: false
        }
    },
    methods: {
        async updateForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid && this.formId) {
                this.formProcess = true;
                StudentProgramService.updateAddress(this.formId, this.formData)
                    .then(response => {
                        this.$emit('updateFormSuccess', true);
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch((e) => {
                        this.showErrors(e, this.$refs.formModalValidate)
                    })
                    .finally(() => {
                        this.formProcess = false
                    })
            }
        }
    }
}
</script>
