<template>
    <div>
        <h5 class="mt-1 mb-4">{{ $t('identity_information').toUpper() }}</h5>
        <div class="border rounded p-4 mb-3">
            <b-row>
                <b-col cols="12" md="4">
                    <div class="label-as-input-div">
                        <label>{{ $t('passport_number') }}</label>
                        <div>
                            {{ this.getObjectValue(studentProgram, 'passport_number') }}
                        </div>
                    </div>
                    <div class="label-as-input-div">
                        <label>{{ $t('name') }}</label>
                        <div>
                            {{ this.getObjectValue(studentProgram, 'name') }}
                        </div>
                    </div>
                    <div class="label-as-input-div">
                        <label>{{ $t('surname') }}</label>
                        <div>
                            {{ this.getObjectValue(studentProgram, 'surname') }}
                        </div>
                    </div>
                    <div class="label-as-input-div">
                        <label>{{ $t('gender') }}</label>
                        <div>
                            {{ this.getObjectValue(studentProgram, 'gender') }}
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" md="4">
                    <div class="label-as-input-div">
                        <label>{{ $t('father_name') }}</label>
                        <div>
                            {{ this.getObjectValue(studentProgram, 'father_name') }}
                        </div>
                    </div>
                    <div class="label-as-input-div">
                        <label>{{ $t('mother_name') }}</label>
                        <div>
                            {{ this.getObjectValue(studentProgram, 'mother_name') }}
                        </div>
                    </div>
                    <div class="label-as-input-div">
                        <label>{{ $t('date_of_birth') }}</label>
                        <div>
                            {{ studentProgram.birthdate ? this.toLocaleDate(studentProgram.birthdate) : '-' }}
                        </div>
                    </div>
                    <div class="label-as-input-div">
                        <label>{{ $t('birthplace') }}</label>
                        <div>
                            {{ this.getObjectValue(studentProgram, 'birthplace') }}
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" md="4">
                    <div class="label-as-input-div">
                        <label>{{ $t('nationality') }}</label>
                        <div>
                            {{ studentProgram.nationality_name }}
                        </div>
                    </div>
                    <div class="label-as-input-div">
                        <label>{{ $t('city') }}</label>
                        <div>
                            {{ this.getObjectValue(studentProgram, 'city_name') }}
                        </div>
                    </div>
                    <div class="label-as-input-div">
                        <label>{{ $t('district') }}</label>
                        <div>
                            {{ this.getObjectValue(studentProgram, 'district_name') }}
                        </div>
                    </div>
                    <div class="label-as-input-div">
                        <label>{{ $t('race') }}</label>
                        <div>
                            {{ this.getObjectValue(studentProgram, 'race_text') }}
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>

        <b-button variant="primary"
                  @click="updateFormShow"
                  v-if="checkPermission('studentprogram_identityupdate') && editMode">
            {{ $t('edit') }}
        </b-button>

        <CommonModal ref="formModal"
                     size="xl"
                     @bHideModalHeaderClose="clearFormData()"
                     :onHideOnlyX="true"
                     v-if="checkPermission('studentprogram_identityupdate') && editMode">
            <template v-slot:CommonModalTitle>
                {{ $t('edit').toUpper() }}
            </template>
            <template v-slot:CommonModalContent>
                <update-form :studentProgram="studentProgram"
                             @updateFormSuccess="updateFormSuccess()"
                             v-if="formProcess=='update'"
                />
            </template>
        </CommonModal>
    </div>
</template>
<script>
    // Component
    import CommonModal from '@/components/elements/CommonModal';

    // Pages
    import UpdateForm from './UpdateForm';

    export default {
        components: {
            CommonModal,
            UpdateForm
        },
        props: {
            studentProgram: {
                type: Object,
                default: null
            },
            editMode: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                formProcess: null
            }
        },
        methods: {
            // Clear
            clearFormData() {
                this.formProcess = null
            },

            // Update
            updateFormShow() {
                this.formProcess = 'update'
                this.$refs.formModal.$refs.commonModal.show()
            },
            updateFormSuccess() {
                this.clearFormData();
                this.$refs.formModal.$refs.commonModal.hide()
                this.$emit('updatedData', true);
            }
        }
    }
</script>

