<template>
    <div>
        <h5 class="mt-1 mb-4">{{ $t('address_information').toUpper() }}</h5>
        <b-row class="mb-4">
            <!--            <b-col cols="12" md="6" class="mb">-->
            <!--                <b-card :header="$t('usa_address')" class="soft h-100">-->
            <!--                    <div class="label-as-input-div">-->
            <!--                        <label>{{ $t('address') }}</label>-->
            <!--                        <div class="height-80">{{ this.getObjectValue(studentProgram, 'address') }}</div>-->
            <!--                    </div>-->
            <!--                    <b-row>-->

            <!--&lt;!&ndash;                        <b-col cols="12" sm="6">&ndash;&gt;-->
            <!--&lt;!&ndash;                            <div class="label-as-input-div">&ndash;&gt;-->
            <!--&lt;!&ndash;                                <label>{{ $t('country') }}</label>&ndash;&gt;-->
            <!--&lt;!&ndash;                                <div>{{ this.getObjectValue(studentProgram, 'adress_country_id') }}</div>&ndash;&gt;-->
            <!--&lt;!&ndash;                            </div>&ndash;&gt;-->
            <!--&lt;!&ndash;                        </b-col>&ndash;&gt;-->
            <!--                        <b-col cols="12" sm="6">-->
            <!--                            <div class="label-as-input-div">-->
            <!--                                <label>{{ $t('state') }}</label>-->
            <!--                                <div>{{ this.getObjectValue(studentProgram, 'address_city_name') }}</div>-->
            <!--                            </div>-->
            <!--                        </b-col>-->
            <!--                        <b-col cols="12" sm="6">-->
            <!--                            <div class="label-as-input-div">-->
            <!--                                <label>{{ $t('city') }}</label>-->
            <!--                                <div>{{ this.getObjectValue(studentProgram, 'address_district_name') }}</div>-->
            <!--                            </div>-->
            <!--                        </b-col>-->
            <!--                        <b-col cols="12" sm="6">-->
            <!--                            <div class="label-as-input-div">-->
            <!--                                <label>{{ $t('apartment') }}</label>-->
            <!--                                <div>{{ this.getObjectValue(studentProgram, 'apartment') }}</div>-->
            <!--                            </div>-->
            <!--                        </b-col>-->
            <!--                        <b-col cols="12" sm="6">-->
            <!--                            <div class="label-as-input-div">-->
            <!--                                <label>{{ $t('postal_code') }}</label>-->
            <!--                                <div>{{ this.getObjectValue(studentProgram, 'address_zip_code') }}</div>-->
            <!--                            </div>-->
            <!--                        </b-col>-->
            <!--                    </b-row>-->
            <!--                </b-card>-->
            <!--            </b-col>-->
            <b-col cols="12" md="6" class="mb">
                <b-card :header="$t('communication_address')" class="soft h-100">
                    <div class="label-as-input-div">
                        <label>{{ $t('street') }}</label>
                        <div class="height-80">{{ this.getObjectValue(studentProgram, 'contact_address') }}</div>
                    </div>
                    <b-row>
                        <b-col cols="12" sm="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('state') }}</label>
                                <div>{{ this.getObjectValue(studentProgram, 'contact_city_name') }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" sm="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('city') }}</label>
                                <div>{{ this.getObjectValue(studentProgram, 'contact_district_name') }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" sm="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('apartment') }}</label>
                                <div>{{ this.getObjectValue(studentProgram, 'apartment') }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" sm="6">
                            <div class="label-as-input-div">
                                <label>{{ $t('zip_code') }}</label>
                                <div>{{ this.getObjectValue(studentProgram, 'address_zip_code') }}</div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>

        <b-button variant="primary"
                  @click="updateFormShow"
                  v-if="checkPermission('studentprogram_updateaddress') && editMode">
            {{ $t('edit') }}
        </b-button>

        <CommonModal ref="formModal"
                     :onHideOnlyX="true"
                     @bHideModalHeaderClose="clearFormData()"
                     v-if="checkPermission('studentprogram_updateaddress') && editMode">
            <template v-slot:CommonModalTitle>
                {{ $t('edit').toUpper() }}
            </template>
            <template v-slot:CommonModalContent>
                <update-form :studentProgram="studentProgram"
                             @updateFormSuccess="updateFormSuccess()"
                             v-if="formProcess=='update'"
                />
            </template>
        </CommonModal>
    </div>
</template>
<script>
// Component
import CommonModal from '@/components/elements/CommonModal';

// Pages
import UpdateForm from './UpdateForm';

export default {
    components: {
        CommonModal,
        UpdateForm
    },
    props: {
        studentProgram: {
            type: Object,
            default: null
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            formProcess: null
        }
    },
    methods: {
        // Clear
        clearFormData() {
            this.formProcess = null
        },

        // Update
        updateFormShow() {
            this.formProcess = 'update'
            this.$refs.formModal.$refs.commonModal.show()
        },
        updateFormSuccess() {
            this.clearFormData();
            this.$refs.formModal.$refs.commonModal.hide()
            this.$emit('updatedData', true);
        }
    },
}
</script>

