<template>
    <div>
        <h5 class="mt-1 mb-4">{{ $t('contact_information').toUpper() }}</h5>
        <b-row class="mb-4">
            <b-col cols="12" md="4">
                <b-card :header="'BAU ' + $t('email')" class="soft h-100">
                    <div class="label-as-input-div">
                        <label>{{ 'BAU ' + $t('email') }}</label>
                        <div>{{ this.getObjectValue(studentProgram, 'email') }}</div>
                    </div>
                    <div class="label-as-input-div">
                        <label>{{ $t('personal_email') }}</label>
                        <div>{{ this.getObjectValue(studentProgram, 'personal_email') }}</div>
                    </div>
                </b-card>
            </b-col>
            <b-col cols="12" md="4">
                <b-card :header="$t('telephone')" class="soft h-100">
                    <div class="label-as-input-div">
                        <label>{{ $t('country') }}</label>
                        <div>{{ getCountryName(this.getObjectValue(studentProgram, 'mobile_tel_country_code')) }}</div>
                    </div>
                    <div class="label-as-input-div">
                        <label>{{ $t('mobile_number') }}</label>
                        <div>{{ this.getObjectValue(studentProgram, 'mobile_tel') }}</div>
                    </div>
                    <div class="label-as-input-div">
                        <label>{{ $t('home_number') }}</label>
                        <div>{{ this.getObjectValue(studentProgram, 'home_tel') }}</div>
                    </div>
                </b-card>
            </b-col>
            <b-col cols="12" md="4">
                <b-card :header="$t('emergency_person')" class="soft h-100">
                    <div class="label-as-input-div">
                        <label>{{ $t('degree_of_proximity') }}</label>
                        <div>{{ this.getObjectValue(studentProgram, 'emergency_person_relation') }}</div>
                    </div>
                    <div class="label-as-input-div">
                        <label>{{ $t('name') + ' ' + $t('surname') }}</label>
                        <div>{{ this.getObjectValue(studentProgram, 'emergency_person_name') }}</div>
                    </div>
                    <div class="label-as-input-div">
                        <label>{{ $t('telephone') }}</label>
                        <div>{{ this.getObjectValue(studentProgram, 'emergency_person_phone') }}</div>
                    </div>
                </b-card>
            </b-col>
        </b-row>
        <b-button variant="primary" @click="updateFormShow"
            v-if="checkPermission('studentprogram_updatecontact') && editMode">
            {{ $t('edit') }}
        </b-button>
        <CommonModal ref="formModal" size="xl" :onHideOnlyX="true"
            v-if="checkPermission('studentprogram_updatecontact') && editMode">
            <template v-slot:CommonModalTitle>
                {{ $t('edit').toUpper() }}
            </template>
            <template v-slot:CommonModalContent>
                <update-form :studentProgram="studentProgram" @updateFormSuccess="updateFormSuccess"
                    v-if="formProcess == 'update'" @smsSendSuccess="smsSendSuccess" />
            </template>
        </CommonModal>
        <CommonModal ref="updatePhoneModal" size="xl" :onHideOnlyX="true">
            <template v-slot:CommonModalTitle>
                {{ $t('show').toUpper() }}
            </template>
            <template v-slot:CommonModalContent>
                <b-form-group :label="$t('email')">
                    <div class="label-as-input">{{ studentProgram.email }}</div>
                </b-form-group>
                <div class="mb-3">
                    <ValidationObserver ref="formSmsVerify">
                        <ValidationProvider name="sms_code" rules="required" v-slot="{ valid, errors }">
                            <sms-input v-model="smsCode" :timerFrom="smsTimer" @timerExpired="timerExpired" />
                        </ValidationProvider>
                    </ValidationObserver>
                </div>
                <div class="mt-3">
                    <b-button type="button" variant="primary" size="lg" block @click="smsVerify"
                        :disabled="smsTimer == 0" :loading="isLoading">
                        {{ $t('verify').toUpper() }}
                    </b-button>
                </div>
            </template>
        </CommonModal>
    </div>
</template>
<script>
// Component
import CommonModal from '@/components/elements/CommonModal';
import countryCodeInputData from "./countryCodeInputData"
import StudentProgramService from "@/services/StudentProgramService";

import { ValidationObserver, ValidationProvider } from 'vee-validate'

// Pages
import UpdateForm from './UpdateForm';
import SmsInput from '@/components/elements/SmsInput.vue';

export default {
    name: "ContactInformationIndex",
    components: {
        ValidationProvider,
        ValidationObserver,
        CommonModal,
        UpdateForm,
        SmsInput
    },
    props: {
        studentProgram: {
            type: Object,
            default: null
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            formProcess: null,
            countryCodeInputData,
            smsCode: null,
            smsTimer: 0,
            phone: "",
            isLoading: false
        }
    },
    methods: {
        // Clear
        clearFormData() {
            this.formProcess = null
        },

        // Update
        updateFormShow() {
            this.formProcess = 'update'
            this.$refs.formModal.$refs.commonModal.show()
        },
        updateFormSuccess() {
            this.clearFormData();
            this.$refs.formModal.$refs.commonModal.hide()
            this.$emit('updatedData', true);
        },
        getCountryName(value) {
            const find = this.countryCodeInputData.find(a => a.value == value)
            if (find) {
                return find.text
            }
            return ""
        },
        timerExpired() {
            this.smsTimer = 0;
        },
        smsSendSuccess(phone) {
            this.phone = phone
            this.$refs.updatePhoneModal.$refs.commonModal.show()
            this.smsTimer = 120
        },
        async smsVerify() {
            const isValid = await this.$refs.formSmsVerify.validate();
            if (!isValid) {
                return
            }

            let formData = {
                verification_code: this.smsCode
            }

            this.isLoading = true
            StudentProgramService.contactVerify({ studentProgramId: this.studentProgram.id, formData })
                .then(response => {
                    let message = response.data.message
                    this.$toast.success(this.$t('api.' + message));
                    this.$refs.updatePhoneModal.$refs.commonModal.hide()
                    this.$emit('updatedData', true);
                })
                .catch(e => {
                    this.showErrors(e)
                }).finally(() => {
                    this.isLoading = false
                })


        }
    }
}
</script>
