<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col md="4">
                    <b-card :header="$t('passport')" class="soft">
                        <ValidationProvider name="passport_country_id" rules="required" v-slot="{valid, errors}">
                            <b-form-group :label="$t('passport_country')">
                                <country-selectbox v-model="formData.passport_country_id"
                                                   :validateError="errors[0]"/>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="passport_number" rules="required" v-slot="{valid, errors}">
                            <b-form-group :label="$t('passport_number')">
                                <b-form-input v-model="formData.passport_number"
                                              :class="errors[0] ? 'is-invalid':''"/>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </b-card>
                </b-col>
                <b-col md="4">
                    <b-card :header="$t('residence_permit')" class="soft">
                        <ValidationProvider name="residence_permit_no" v-slot="{valid, errors}">
                            <b-form-group :label="$t('residence_permit_no')">
                                <b-form-input v-model="formData.residence_permit_no"
                                              :class="errors[0] ? 'is-invalid':''">
                                </b-form-input>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                        <b-form-group :label="$t('validity_dates')">
                            <v-date-picker v-model="formData.validity_dates" is-range locale="en">
                                <template v-slot="{ inputValue, inputEvents }">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-grow-1">
                                            <b-form-group class="m-0 mr-1">
                                                <input :value="inputValue.start"
                                                       v-on="inputEvents.start"
                                                       :placeholder="$t('start_date')"
                                                       class="form-control"
                                                />
                                            </b-form-group>
                                        </div>
                                        <div class="flex-grow-1">
                                            <b-form-group class="m-0 ml-1">
                                                <input :value="inputValue.end"
                                                       v-on="inputEvents.end"
                                                       :placeholder="$t('end_date')"
                                                       class="form-control"
                                                />
                                            </b-form-group>
                                        </div>
                                    </div>
                                </template>
                            </v-date-picker>
                        </b-form-group>
                    </b-card>
                </b-col>
                <b-col md="4">
                    <b-card :header="$t('foreign_student')" class="soft">
                        <ValidationProvider name="document_type" rules="required" v-slot="{valid, errors}">
                            <b-form-group :label="$t('document_type')">
                                <parameter-selectbox v-model="formData.foreign_document_type"
                                                     code="foreign_document_types"
                                                     sort="id"
                                                     :validateError="errors[0]"/>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="foreign_document_number" rules="required" v-slot="{valid, errors}">
                            <b-form-group :label="$t('foreign_document_number')">
                                <b-form-input v-model="formData.foreign_document_number"
                                              :class="errors[0] ? 'is-invalid':''"
                                />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </b-card>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center">
                <b-button variant="primary" @click="updateForm" :disabled="formProcess">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Components
    import  CountrySelectbox from '@/components/interactive-fields/CountrySelectbox';
    import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';

    // Services
    import StudentProgramService from '@/services/StudentProgramService'

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import moment from 'moment';

    export default {
        components: {
            CountrySelectbox,
            ParameterSelectbox,

            ValidationProvider,
            ValidationObserver
        },
        props: {
            studentProgram: {
                type: Object,
                default: null
            }
        },
        created() {
            this.formId = this.studentProgram.id;
            this.formData = {
                passport_country_id: this.studentProgram.passport_country_id,
                passport_number: this.studentProgram.passport_number,
                residence_permit_no: this.studentProgram.passport_number,
                validity_dates: {
                    start: this.studentProgram.residence_permit_start_date,
                    end: this.studentProgram.residence_permit_end_date
                },
                foreign_document_type: this.studentProgram.foreign_document_type,
                foreign_document_number: this.studentProgram.foreign_document_number
            }
        },
        data() {
            return {
                formId: null,
                formData: {},
                formProcess: false
            }
        },
        methods: {
            async updateForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {

                    let formData = {
                        passport_country_id: this.formData.passport_country_id,
                        passport_number: this.formData.passport_number,
                        residence_permit_no: this.formData.residence_permit_no ? this.formData.residence_permit_no : null,
                        residence_permit_start_date: this.formData.validity_dates && this.formData.validity_dates.start ? moment(this.formData.validity_dates.start).format('MM/DD/YYYY') : null,
                        residence_permit_end_date: this.formData.validity_dates && this.formData.validity_dates.end ? moment(this.formData.validity_dates.end).format('MM/DD/YYYY') : null,
                        foreign_document_number: this.formData.foreign_document_number,
                        foreign_document_type: this.formData.foreign_document_type
                    }

                    this.formProcess = true;
                    StudentProgramService.updateForeign(this.formId, formData)
                                         .then(response => {
                                             this.$emit('updateFormSuccess', true);
                                             this.$toast.success(this.$t('api.' + response.data.message));
                                         })
                                         .catch(e => {
                                             this.showErrors(e, this.$refs.formModalValidate)
                                         })
                                         .finally(() => {
                                             this.formProcess = false
                                         })
                }
            }
        }
    }
</script>

